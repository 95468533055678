<template>
  <full-screen v-if="loading" class="rtl" max-width="1200px">
    <v-container style="height: 100%">
      <v-row align="center" style="height: 100%">
        <v-col class="text-center">
          <v-progress-circular indeterminate size="60"></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
  </full-screen>

  <router-view v-else></router-view>
</template>

<script>
import FullScreen from "@/components/FullScreen.vue";

export default {
  components: { FullScreen },
  data() {
    return {
      loading: true
    };
  },
  watch: {
    $route: {
      handler() {
        this.findStepIndex();
      }
    }
  },
  methods: {
    findStepIndex() {
      let routeName = this.$route.name;
      let stepIndex = this.$store.registration.steps.findIndex(
        (step) => step.to.name == routeName
      );
      this.$store.registration.stepIndex = stepIndex;
    }
  },
  async mounted() {
    try {
      await this.$account.verifyAuth("user", "admin", "demo");

      if (this.$store.registration.fetchRegistrationDetailsFromHash()) {
        return;
      }
      if (this.$store.registration.loadRegistartionState()) {
        return;
      }
      if (this.$account.auth) {
        this.$store.registration.fetchRegistrationDetailsFromToken(
          this.$router
        );
      }
    } catch (error) {
      this.$tracing.captureException(error);
    } finally {
      this.loading = false;
      this.findStepIndex();
    }
  }
};
</script>
